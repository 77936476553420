import { Dispatch, RefObject, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';

import AdditionalFilters from 'components/AdditionalFilters';
import DateTimeRange from 'components/DateTimeRange';
import type { ParssedDateTimeResult } from 'components/DateTimeRange/Services/ConvertString';
import { EntitySearchFieldsEnum } from 'components/EntitySearch/Models/Enums';
import { WorksheetSignalMessageEventTypes, WorksheetStores } from 'components/Worksheets/Models/Enums';
import { additionalSearchPropParser } from 'components/Worksheets/Models/Parsers';
import type {
  WorksheetMetaProps,
  WorksheetResponse,
} from 'components/Worksheets/Models/WorksheetResponse';
import {
  useLoadWorksheet,
  useMutateWorksheet,
  WorksheetMutationTypes
} from 'components/Worksheets/Services/WorksheetHooks';

import { SurveillanceEntityStatus, SurveillanceModeEnum } from '../../Models/Enums';
import { DEFAULT_SEARCH_ITEMS, SearchRequest } from '../../Models/ReportsRequest';

import { isSearchRequestEmpty, searchWorksheetParsers } from './Models/Parsers';
import { FilterOptions, ThreeStateFilter } from './Components';
import SurveillanceSearchEntity from './SurveillanceSearchEntity';

import { notNil } from 'helpers/Utils/misc';
import {
  getAdditionalPropsAsSearchItems,
  getAdditionalPropsFromSearchItems,
} from 'modules/CargoTracker/Components/CargoSearch/Models/Parsers';

import eventBus from 'server/EventBus';

import './SearchBar.scss';

type SearchBarProps = {
  isLoadingWorksheet: boolean;
  searchItems: SearchRequest | undefined;
  setSearchItems: Dispatch<SetStateAction<SearchRequest | undefined>>;
  setLastModified: Dispatch<SetStateAction<DateTime | undefined>>;
  searchContainerRef: RefObject<HTMLElement>;
  activeWorksheet?: string | null;
  resultsMode?: SurveillanceModeEnum;
  onLoadingWorksheetError?: () => void;
};

const SearchBar = (props: SearchBarProps): JSX.Element => {
  const {
    isLoadingWorksheet,
    searchItems,
    setSearchItems,
    setLastModified,
    searchContainerRef,
    activeWorksheet,
    resultsMode = SurveillanceModeEnum.Results,
    onLoadingWorksheetError,
  } = props;

  const [ isParsingData, setIsParsingData ] = useState<boolean>(false);
  const [ isClearDisabled, setIsClearDisabled ] = useState<boolean>(false);

  // Worksheet data load
  const {
    data,
    error: loadingWorksheetError,
    isLoading: isLoadingWorksheetSearch,
  } = useLoadWorksheet(
    WorksheetStores.Surveillance,
    activeWorksheet ?? undefined,
    searchWorksheetParsers
  );

  // Worksheet mutate loader
  const { worksheet, mutateWorksheet, mutateAdditionalProps, isMutating } = useMutateWorksheet(
    WorksheetStores.Surveillance,
    activeWorksheet ?? undefined
  );

  useEffect(() => {
    if (loadingWorksheetError?.response?.status === 403) {
      onLoadingWorksheetError && onLoadingWorksheetError();
    }
  }, [loadingWorksheetError, onLoadingWorksheetError]);

  useEffect(() => {
    const onWSUpdated = (e: CustomEvent<Partial<WorksheetResponse>>): void => {
      if (e.detail.worksheetId === worksheet.worksheetId) {
        mutateWorksheet({
          type: WorksheetMutationTypes.Full,
          payload: {
            ...worksheet,
            ...e.detail
          }
        });
      }
    };

    eventBus.on(
      WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
      onWSUpdated
    );

    return () => {
      eventBus.remove(
        WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
        onWSUpdated
      );
    };
  }, [worksheet, mutateWorksheet]);

  // Mutate worksheet with data
  useEffect(() => {
    if (!data || data.store !== WorksheetStores.Surveillance) {
      return;
    }
    mutateWorksheet({type: WorksheetMutationTypes.Full, payload: data});
    eventBus.dispatch(
      WorksheetSignalMessageEventTypes.WORKSHEET_UPDATED,
      data
    );
  }, [data, mutateWorksheet, activeWorksheet]);

  const modifyReportState = useCallback(
    (items: SearchRequest) => {
      // Search in searchRequestFields for report state and remove if there is already searchRequestFields with Report State
      const searchRequestFields = items.searchRequestFields?.filter(
        srf =>
          srf.searchField !== EntitySearchFieldsEnum.SurveillanceReportState
      );

      // Add proper Report State(s) (Active/ActiveWithComments/Escalated or Reviewed) into searchRequestFields

      const newSearchRequestFields = resultsMode === SurveillanceModeEnum.Reviewed ?
        [{searchTerm: SurveillanceEntityStatus.Reviewed,
          searchField: EntitySearchFieldsEnum.SurveillanceReportState,
          searchEntityId: activeWorksheet ?? '',
          metaData: [],
        }] :
        [SurveillanceEntityStatus.Active, SurveillanceEntityStatus.ActiveWithComments, SurveillanceEntityStatus.Escalated].map(status => (
          { searchTerm: status,
            searchField: EntitySearchFieldsEnum.SurveillanceReportState,
            searchEntityId: activeWorksheet ?? '',
            metaData: []}
        ));

      return {
        ...items,
        searchRequestFields: [
          ...(searchRequestFields ?? []),
          ...newSearchRequestFields,
        ],
      };
    },
    [activeWorksheet, resultsMode]
  );

  // Load stored searchItems from worksheet
  useEffect(() => {
    // If there is no worksheet or data has been already restored -> exit
    if (!worksheet?.additionalSearchProperties) {
      return;
    }

    // Directly after saving to worksheet value is JSON string so checking and parsing this
    const props: WorksheetMetaProps[] = additionalSearchPropParser(worksheet.additionalSearchProperties);

    // Load Additional Search Props Into searchItems
    let searchItemsFromWorksheet = getAdditionalPropsAsSearchItems<SearchRequest>(props);

    // Add / Remove Report State (Results/Reviewed) based on current mode
    searchItemsFromWorksheet = modifyReportState(searchItemsFromWorksheet);

    // Mutate searchItems with loaded Additional Search Props
    setSearchItems({
      ...searchItemsFromWorksheet,
      worksheetId: activeWorksheet
    });

    // Update footer with last modified date
    setLastModified(worksheet.lastModifiedParsed);
  }, [modifyReportState, setLastModified, setSearchItems, worksheet]);

  // handle search request params update
  const onSearchRequestUpdated = async (
    updatedParams?: Partial<SearchRequest>
  ): Promise<void> => {
    if (updatedParams) {
      const updatedSearchParams = {
        ...searchItems,
        ...updatedParams,
        worksheetId: activeWorksheet
      };
      const additionalProps = getAdditionalPropsFromSearchItems(updatedSearchParams);

      setSearchItems(searchParams => ({ ...searchParams, ...updatedParams }));
      mutateAdditionalProps(additionalProps);
    }
  };

  const handleDateTimeParsed = (m: ParssedDateTimeResult):void => {
    const dateValue = {
      original: m.original,
      fromDate: m.fromString,
      toDate: m.toString
    };
    onSearchRequestUpdated({ date: dateValue });
    setIsParsingData(false);
  };

  // Handle when DateTime input is cleared
  const handleDateTimeEmpty = ():void => {
    onSearchRequestUpdated({ date: undefined });
    setIsParsingData(false);
  };

  // Handle clear button press - clear all search items and also search data
  const handleClear = (): void => {
    onSearchRequestUpdated({ ...DEFAULT_SEARCH_ITEMS });
  };

  const loadingOngoing: boolean = useMemo(
    () =>
      isLoadingWorksheet ||
      isParsingData ||
      isLoadingWorksheetSearch ||
      !!loadingWorksheetError ||
      isMutating,
    [
      isLoadingWorksheet,
      isLoadingWorksheetSearch,
      loadingWorksheetError,
      isMutating,
      isParsingData,
    ]
  );

  const isSearchEmpty: boolean = useMemo(() => isSearchRequestEmpty(searchItems ?? {}),[searchItems]);
  const additionalFiltersCount = useMemo(() => {
    const selectedFilters = [
      searchItems?.onlyAttachments,
      searchItems?.onlyEmpty,
    ].filter(notNil);
    return selectedFilters.length;
  }, [searchItems]);

  const onAdditionalFilterChanged = (
    fieldName: string,
    value: boolean | null
  ): void => {
    onSearchRequestUpdated({ [fieldName]: value });
  };

  const additionalFiltersNotes = {
    empty: [
      {
        key: FilterOptions.Only,
        value: 'Will only INCLUDE items with the Empty content',
      },
      {
        key: FilterOptions.Exclude,
        value:
          'Will explicitly EXCLUDE all items with the Empty content from returned results',
      },
    ],
    attachment: [
      {
        key: FilterOptions.Only,
        value: 'Will only INCLUDE items with the Attachment',
      },
      {
        key: FilterOptions.Exclude,
        value:
          'Will explicitly EXCLUDE all items with the Attachment from returned results',
      },
    ],
  };

  return (
    <div className="surveillance-search-container grow-to-fill">
      <SurveillanceSearchEntity
        onRequestChanged={onSearchRequestUpdated}
        searchItems={searchItems}
        className={clsx('surveillance-search-input', {'search-ongoing': loadingOngoing})}
        searchContainerRef={searchContainerRef}
      />
      <AdditionalFilters
        className='surveillance-search-container-additional-filters'
        filterCount={additionalFiltersCount || undefined}
      >
        <ThreeStateFilter
          label='Empty'
          value={searchItems?.onlyEmpty}
          notes={additionalFiltersNotes.empty}
          disabled // TODO: temporary hotfix, remove when needed
          onChange={(value): void => onAdditionalFilterChanged('onlyEmpty', value)}
        />
        <ThreeStateFilter
          label='Attachment'
          value={searchItems?.onlyAttachments}
          notes={additionalFiltersNotes.attachment}
          onChange={(value): void => onAdditionalFilterChanged('onlyAttachments', value)}
        />
      </AdditionalFilters>
      <DateTimeRange
        placeholder='Date Time'
        onEmptyValue={handleDateTimeEmpty}
        onDateParseError={():void => setIsParsingData(false)}
        onDateParsed={handleDateTimeParsed}
        onParsingStart={():void => setIsParsingData(true)}
        onFocus={():void => setIsClearDisabled(true)} // disable clear on input focus as there is issue when loosing input focus and clicking "clear" - #1622 - other option would be to handleClear on mouseDown and prevent other inputs saving content
        onBlur={():void => setIsClearDisabled(false)}
        defaultValue={searchItems?.date?.original ?? ''}
        showErrorMessage={true}
        required={false}
        className="surveillance-search-date"
      />
      <Button
        text
        size="small"
        disabled={isClearDisabled || loadingOngoing || isSearchEmpty}
        onClick={handleClear}
        className="surveillance-clear"
      >
        Clear
      </Button>
    </div>
  );
};
export default SearchBar;
