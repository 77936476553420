import { humanDate } from 'helpers/Utils/formatters';

import { SanctionStatusEnum } from './Enums';
import { notNil } from 'helpers/Utils/misc';

import type { LegalEntitySanctionResponse, SanctiondItem, VesselSanctionResponse } from './SanctionedItems';

export type Property = [string, string];
export type Status = [string, SanctionStatusEnum];

/**
 * Given a reponse object containing details of a vessel sanction search, returns
 * two collections of properties. One with text based properties and another
 * with a collection of SanctionStatus'
 *
 * @param {VesselSanctionResponse} payload - The response payload to parse
**/
export const parseVesselProps = (payload: VesselSanctionResponse): SanctiondItem => {

  const { id, providerName, partitionKey } = payload;

  const general: Property[] = Array.from( new Map<string, string | undefined>([
    ['Name', payload.shipName],
    ['IMO Number', payload.imoNumber],
    ['Registered Owner', payload.registeredOwner],
    ['Group Owner', payload.groupBeneficialOwner],
    ['Current Operator', payload.operator],
    ['Status', payload.shipStatus],
    ['Designated date', payload.designatedDate ? humanDate(payload.designatedDate, { dayName: false }) : 'Unknown'],
    ['Created On', payload.createdOn ? humanDate(payload.createdOn, { dayName: false }) : 'Unknown'],
  ])).filter<[string, string]>((item): item is Property => {
    const [, value ] = item;
    return value !== undefined
  })

  // const general: Property[] = foo
  const status: Status[] =  Array.from(
    new Map<string, SanctionStatusEnum>([
      ['Overall Status', payload.legalOverall ?? -1 ],
      ['EU Sanction List', payload.shipEUSanctionList ?? -1 ],
      ['BES Sanction List', payload.shipBESSanctionList ??-1 ],
      ['OFAC Sanction List', payload.shipOFACSanctionList ??-1 ],
      ['Swiss Sanction List', payload.shipSwissSanctionList ??-1 ],
      ['Owner Australian Sanction List', payload.shipOwnerAustralianSanctionList ??-1 ],
      ['Owner UN Sanction List', payload.shipOwnerUNSanctionList ??-1 ],
      ['Owner BES Sanction List', payload.shipOwnerBESSanctionList ??-1 ],
      ['Owner OFAC Sanction List', payload.shipOwnerOFACSanctionList ??-1 ],
      ['Owner OFAC Sanctioned Country', payload.shipOwnerOFACSanctionedCountry ??-1 ],
      ['Owner FAFT Jurisdiction', payload.shipOwnerFATFJurisdiction ?? -1 ],
      ['Parent Owner FAFT Jurisdiction', payload.shipOwnerParentFATFJurisdiction ?? -1 ],
      ['Parent Owner OFAC Sanctioned Country', payload.shipOwnerParentOFACSanctionedCountry ?? -1 ],
      ['Dark Activity Indicator', payload.shipDarkActivityIndicator ??-1 ],
      ['Owner Parent Company Non Compliance', payload.shipOwnerParentCompanyNonCompliance ??-1 ],
      ['STS Partner Non Compliance Last 12m', payload.shipSTSPartnerNonComplianceLast12m ??-1 ],
      ['Sanctioned Country Port CallLast 3m', payload.shipSanctionedCountryPortCallLast3m ??-1 ],
      ['Sanctioned Country Port CallLast 6m', payload.shipSanctionedCountryPortCallLast6m ??-1 ],
      ['Sanctioned Country Port CallLast 12m', payload.shipSanctionedCountryPortCallLast12m ??-1 ],
      ['Flag Sanctioned Country', payload.shipFlagSanctionedCountry ??-1 ],
      ['Flag Disputed', payload.shipFlagDisputed ??-1 ],
      ['Historical Flag Sanctioned Country', payload.shipHistoricalFlagSanctionedCountry ??-1 ],
    ])
  );


  return { general, status, id, providerName: providerName ?? partitionKey }
}

export const parseEntityProps = (payload: LegalEntitySanctionResponse): SanctiondItem => {

  const { id, providerName, partitionKey } = payload;

  const general: Property[] = Array.from( new Map<string, string | undefined | null>([
    ['Name', payload.fullName],
    ['Short Name', payload.shortCompanyName],
    ['Country Name', payload.countryName],
    ['Registration', payload.nationalityofRegistration],
    ['Telephone', payload.telephone],
    ['Source', payload.providerName],
    ['Designated date', payload.designatedDate ? humanDate(payload.designatedDate, { dayName: false }) : 'Unknown'],
    ['Created On', payload.createdOn ? humanDate(payload.createdOn, { dayName: false }) : 'Unknown'],
  ])).filter<[string, string]>((item): item is Property => {
    const [, value ] = item;
    return notNil(value)
  })

  const status: Status[] =  Array.from(
    new Map<string, SanctionStatusEnum>([
			['Company Overall', payload.companyOverallComplianceStatus ?? SanctionStatusEnum.Unknown],
      ['Parent Company Compliance', payload.parentCompanyComplianceRisk ?? SanctionStatusEnum.Unknown ],
      ['FATF Jurisdiction', payload.companyInFATFJurisdiction ?? SanctionStatusEnum.Unknown],
      ['OFAC Sanctioned Country', payload.companyInOFACSanctionedCountry ?? SanctionStatusEnum.Unknown],
      ['Australian Sanction List', payload.companyOnAustralianSanctionList ?? SanctionStatusEnum.Unknown],
      ['BES Sanction List', payload.companyOnBESSanctionList ?? SanctionStatusEnum.Unknown],
      ['EU Sanction List', payload.companyOnEUSanctionList ?? SanctionStatusEnum.Unknown],
      ['Canadian Sanction List', payload.companyOnCanadianSanctionList ?? SanctionStatusEnum.Unknown],
      ['OFAC Non SDN Sanction List', payload.companyOnOFACNonSDNSanctionList ?? SanctionStatusEnum.Unknown],
      ['OFAC SSI List', payload.companyOnOFACSSIList ?? SanctionStatusEnum.Unknown],
      ['OFAC Sanction List', payload.companyOnOFACSanctionList ?? SanctionStatusEnum.Unknown],
      ['Swiss Sanction List', payload.companyOnSwissSanctionList ?? SanctionStatusEnum.Unknown],
      ['UAE Sanction List', payload.companyOnUAESanctionList ?? SanctionStatusEnum.Unknown],
      ['UN Sanction List', payload.companyOnUNSanctionList ?? SanctionStatusEnum.Unknown]
    ])
  );

  return { general, status, id, providerName: providerName ?? partitionKey }
}




