import { ReactElement, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { Column, type ColumnBodyOptions, ColumnSortEvent } from 'primereact/column';
import { DataTable, DataTableSelectionSingleChangeEvent, DataTableValueArray } from 'primereact/datatable';
import { DataView } from 'primereact/dataview';

import GridElement from 'components/GridElement';

import {
  sortByDateTime,
  sortByNumerical,
} from 'helpers/DataTable/SortingFunctions';
import { ReadableDate, SingleLineArrayMore } from 'helpers/DataTable/Templates/ColumnTemplates';
import { SingleLineEmpty } from 'helpers/DataTable/Templates/ColumnTemplates/SingleLineEmpty';
import { SingleLineStatus } from 'helpers/DataTable/Templates/ColumnTemplates/SingleLineStatus';
import { DATE_FORMAT, EMPTY_MESSAGE } from 'modules/DistList/Models/Consts';

import type F from 'types/generic-type';

import type { apiSingleMailEmailResponse } from '../../Models/apiResponse';

interface SingleProps {
  singleMessages: apiSingleMailEmailResponse[];
  selectedSingleId: string | null;
  handleSelection: (value?: apiSingleMailEmailResponse) => void;
  handleSelectionChange: (e: DataTableSelectionSingleChangeEvent<DataTableValueArray>) => Promise<void>;
  isLoadingData: boolean;
}

//TODO: Just a placeholder, same as List for now, will be updated in following tasks
const Single = (props: SingleProps): ReactElement => {
  const { singleMessages, selectedSingleId, handleSelection, handleSelectionChange, isLoadingData } = props;

  const [ selectedSingle, setSelectedSingle] = useState<apiSingleMailEmailResponse | null>(null);

  const isMobile = useMediaQuery({ query: '(max-width: 960px)' });

  useEffect(() => {
    const foundSingle = singleMessages.find(list => list.id === selectedSingleId);

    setSelectedSingle(foundSingle ?? null);
  }, [selectedSingleId, singleMessages]);

  const itemTemplate = (item: apiSingleMailEmailResponse): ReactElement => (
    <div className={clsx('list-element')}
      onClick={(): void => handleSelection(item)}
      key={item.id}>
      <GridElement header='Subject' body={item.subject} />
      <GridElement header='List of Recipients' body={item.recipientEmails.join(', ')} />
      <GridElement header='Date' body={ReadableDate(item, { field: 'messageDate' }, DATE_FORMAT)} />
      <GridElement header='Attachment Name' body={SingleLineEmpty(item, { field: 'attachmentNames' })} />
      <GridElement header='Link Status' body={SingleLineStatus(item, { field: 'attachmentStatus,attachmentNames' })} />
    </div>
  );

  return (
    <section className='grow-to-fill'>
      {isMobile ?
        <DataView value={singleMessages}
          itemTemplate={itemTemplate}
          className='distlist__view single-data__view grow-to-fill no-background'
          emptyMessage={EMPTY_MESSAGE}/> :
        <DataTable
          className='distlist__table--distLists distlist__table--single grow-to-fill'
          dataKey='id'
          value={singleMessages}
          sortField='messageDate'
          sortOrder={-1}
          scrollable
          removableSort
          selectionMode='single'
          selection={selectedSingle}
          onSelectionChange={handleSelectionChange}
          metaKeySelection={false}
          emptyMessage={EMPTY_MESSAGE}
          loading={isLoadingData}
        >
          <Column header='Subject' field='subject' sortable/>
          <Column header='List of Recipients'
            field='recipientEmails,recipientsCount'
            sortable
            body={data => SingleLineArrayMore(data, { field: 'recipientEmails,recipientsCount' } as ColumnBodyOptions, 2)}
          />
          <Column header='Date'
            field='messageDate'
            body={(data, config): ReactElement => ReadableDate<apiSingleMailEmailResponse>(data, config, DATE_FORMAT)}
            sortable
            sortFunction={(e: ColumnSortEvent): F<DateTime>[] => sortByDateTime(e, 'messageDate')}
          />
          <Column header='Attachment Name'
            field='attachmentNames'
            sortable
            body={SingleLineEmpty}
          />
          <Column header='Link Status'
            field='attachmentStatus,attachmentNames'
            sortable
            body={SingleLineStatus}
            sortFunction={(e: ColumnSortEvent) => sortByNumerical(e, 'attachmentStatus')}
          />
        </DataTable>}
    </section>);

};

export default Single;